import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.info("Connected Controller")

    var extensionId = "iifpijpbighbakbebbcnjgnnonifmmim"
    var _this = this

    chrome.runtime.sendMessage(extensionId, { message: "version" },
      function (reply) {
        if (reply) {
          console.info("Extension is installed")

          // If we need to control the precise version of the extension,
          // we can do it here.
          // if (reply.version) {
          //   if (reply.version >= requiredVersion) {
          //   }
          // }
        } else {
          _this.element.classList.remove("hidden")
          console.info("Extension isn't installed: Displaying CTA")
        }
      }
    )
  }
}
